/// **************
/// **********
/// ATOMS 
/// **********
/// **************

// GENERAL

.button,
.control.has-icons-left .icon,
.control.has-icons-right .icon,
.input,
.pagination-ellipsis,
.pagination-link,
.pagination-next,
.pagination-previous,
.select,
.select select,
.textarea {
  height: 2.534em;
}

.button {
  &.is-active,
  &:active {
    box-shadow: inset 1px 1px 4px rgba($grey-darker, 0.3);
  }
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      border-color: darken($color, 5);

      &.is-hovered,
      &:hover {
        background-color: darken($color, 10);
      }

      &.is-active,
      &:active {
        box-shadow: inset 1px 0 3px rgba($grey-darker, 0.3);
        background-color: darken($color, 10);
      }
    }
  }

  &.is-loading:after {
    border-color: transparent transparent $grey-light $grey-light;
  }

  & i { padding-right: 1em; } // padding on icons
}

.input,
.textarea {
  box-shadow: none;
}

.box,
.card {
  box-shadow: 0 0 0 1px $border;
}

.notification {
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      a:not(.button) {
        color: $color-invert;
        text-decoration: underline;
      }
    }
  }
}

.progress,
.tag {
  border-radius: $radius;
}


//TYPOGRAPHY

h1,h2,h3,h4,h5,h6,
.content h1, .content h2, .content h3, .content h4, .content h5, .content h6 {
  font-family: $family-secondary;
  font-weight: 400;
}
.has-text-white {
  h1,h2,h3,h4,h5,h6,
  .content h1, .content h2, .content h3, .content h4, .content h5, .content h6 {
    color: $white;
  }
  hr {
    background-color: $white;
  }
  a {
    color: lighten($color: $link, $amount: 70%);
    &:hover {
      color: lighten($color: $link, $amount: 95%);
    }
  }
}

.fa {
  margin-right: .2em;
}

.hidden-text {
  display: inline-block;
  height: 1px;                        /* be sure to set height & width */
  width:  1px;
  /* Hide the text. */
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

// Lists

ul.checklist {
  list-style: none;
  padding: 0;
  li {
    padding-left: 1.3em;
    margin-bottom: .5em;
    &:before {
      content: "\f00c";
      font-family: 'Font Awesome 5 Free';
      font-weight: 400;
      display: inline-block;
      margin-left: -1.3em; /* same as padding-left set on li */
      margin-right: .4em;
      width: 1.3em; /* same as padding-left set on li */
    }
  }
}



// Tweeks /////////////////

// cards
.equal-height {
  display: flex;
  flex-direction: column;
  height: 100%;
  &.card-content {
    flex: auto;
  }
}
// typography
.hidden-text {
  display: inline-block;
  height: 1px;                        /* be sure to set height & width */
  width:  1px;
  /* Hide the text. */
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}


/// **************
/// **********
// TEMPLATES
/// **********
/// **************


// HERO
.hero-top {
  position: relative;
  z-index: 999;
  background: url('../img/door-1089560_1920.jpg') no-repeat center;
  background-size: cover;
}
.hero-top-left {
  height: 430px;
}
.hero-top-overlay {
  position:absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba($primary,.1);
}
.background-color-primary-half {
  background-color: rgba($primary, .6);
}

.cta-programare {
  min-height: 100%;
  background: url('../img/key-2114046_1920.jpg') no-repeat center rgba($primary, .4);
  background-size: cover;
  background-blend-mode: multiply;

}

#bus {
  width: 90%;
}

#whatsapp {
  position: fixed;
  z-index: 10;
  border-radius: 10px;
  padding: 0 .3em;
  left: 10px;
  bottom: 10px;
  background-color: #42D54B;
  a {
    color: #fff;
    &:hover {
      color: #ddd;
    }
  }
}

#facebook {
  position: fixed;
  z-index: 10;
  border-radius: 10px;
  padding: 0 .3em;
  right: 10px;
  bottom: 10px;
  background-color: #1877F2;
  a {
    color: #fff;
    &:hover {
      color: #ddd;
    }
  }
}
@include tablet {
  #phone-number, #deblocheaza {
    display: none;
  }
}

@include mobile {
#phone-number {
  position: fixed;
  z-index: 10;
  border-radius: 10px;
  padding: 0 .3em;
  right: 100px;
  left: 100px;
  bottom: 10px;
  background-color: #1877F2;
  &:hover {
    background-color: #42D54B;
  }
  a {
    color: #fff;
    padding: 1em 0;
    &:hover {
      color: #ddd;
    }
  }
}

#deblocheaza {
  display: none;
  position: fixed;
  font-weight: bold;
  z-index: 10;
  border-radius: 10px;
  padding: 0 .3em;
  right: 100px;
  left: 100px;
  bottom: 65px;
  color: #42D54B;
  background-color: #FFF;
}
#phone-number:hover + #deblocheaza {
  display: block;
}

}


.performante {
  height: 100%;
}
