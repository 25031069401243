// COLORS
// greys
$grey-darker:  #222;
$grey-dark:    #333;
$grey:         #888;
$grey-light:   #ccc;
$grey-lighter: #e9e9e9;
$white-ter: hsl(0, 0%, 94%);
$white: hsl(0, 0%, 98%);

$light: $white-ter; 
$dark: $grey-darker; 

// brand colors
$yellow:       #FFCB00;
$blue:         #006AFF;
$red:          #9D0B0F;
$gold:         #F1BF98;
$green:        #00A693;

// text colors
$text: $grey-dark;
$text-strong: $grey-dark;

// TYPOGRAPHY
$family-sans-serif: "Montserrat", Arial, sans-serif;
$family-code: monospace;
$family-primary: $family-sans-serif;
$family-secondary: $family-sans-serif;
$weight-normal: 400;
$weight-bold: 700;

// MAIN BODY settings
$body-background-color: $white;
$body-min-width: 300px;
$body-rendering: optimizeLegibility;
$body-family: $family-primary;
$body-overflow-x: hidden;
$body-overflow-y: scroll;
// body text
$body-color: $text;
$body-size: 16px;
$small-font-size: 0.875em;
$body-weight: $weight-normal;
$body-line-height: 1.5;
// <code>
$code-family: $family-code;
$code-padding: 0.25em 0.5em 0.25em;
$code-weight: normal;
$code-size: 0.875em;
// <hr>
$hr-background-color: $grey-light;
$hr-height: 2px;
$hr-margin: 1.5rem 0;
// <strong>
$strong-color: $text-strong;
$strong-weight: $weight-bold;
// <pre>
$pre-font-size: 0.875em;
$pre-padding: 1.25rem 1.5rem;
$pre-code-font-size: 1em;
// radius
$radius: 2;
$radius-small: 0;
$radius-large: 0;

$subtitle-color: $grey;
// buttons
$button-background-color: $grey-lighter;
$button-border-color: darken($button-background-color, 5);
// navbar
$navbar-background-color: #767676;
$navbar-item-color: rgba(#fff, 0.6);
$navbar-item-hover-color: #fff;
$navbar-item-active-color: #fff;
$navbar-item-hover-background-color: rgba(#000, 0.1);
$navbar-dropdown-arrow: $navbar-item-color;