/* montserrat-regular - latin-ext_latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../webfonts/montserrat-v15-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../webfonts/montserrat-v15-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../webfonts/montserrat-v15-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../webfonts/montserrat-v15-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../webfonts/montserrat-v15-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../webfonts/montserrat-v15-latin-ext_latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin-ext_latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../webfonts/montserrat-v15-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../webfonts/montserrat-v15-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../webfonts/montserrat-v15-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../webfonts/montserrat-v15-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
       url('../webfonts/montserrat-v15-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../webfonts/montserrat-v15-latin-ext_latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}

 // fontawesome 
@import "fontawesome/fontawesome.scss";
//@import "fontawesome/regular.scss";
@import "fontawesome/solid.scss";
@import "fontawesome/brands.scss";
@import "fontawesome/v4-shims.scss";